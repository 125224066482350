import React, { useEffect } from 'react';
import { AppContext, AppProps } from 'next/app';
import i18next from 'i18next';
import hoistNonReactStatics from 'hoist-non-react-statics';
import initI18n from './init';
import { Language } from './types';
import { useTranslation } from 'react-i18next';
import parser from 'ua-parser-js';

const appWithTranslation = (WrappedComponent) => {
  const ChangeLanguageWrapper = (
    props: AppProps & {
      deviceType: string;
      translations: Record<string, unknown>;
      locale: Language;
    }
  ) => {
    const { i18n } = useTranslation();
    useEffect(() => {
      if (!i18next.hasResourceBundle(props.locale, 'translation')) {
        i18n.addResourceBundle?.(props.locale, 'translation', props.translations);
        i18next.changeLanguage(props.locale).then();
      }
    });
    return <WrappedComponent key={props.locale} {...props} />;
  };

  const AppWithTranslation = (
    props: AppProps & {
      deviceType: string;
      translations: Record<string, unknown>;
      locale: Language;
    }
  ) => {
    if (!i18next.isInitialized) {
      initI18n(props.translations, props.locale).then();
    }
    if (i18next.hasResourceBundle(props.locale, 'translation')) {
      i18next.changeLanguage(props.locale).then();
    }

    return <ChangeLanguageWrapper {...props} />;
  };

  AppWithTranslation.getInitialProps = async (appContext: AppContext) => {
    const isServer = typeof window === 'undefined';
    const appProps = (await WrappedComponent.getInitialProps?.(appContext)) || {};
    const deviceType = parser(appContext.ctx?.req?.headers['user-agent']).device?.type || 'desktop';

    let translations = i18next?.getDataByLanguage?.(appContext.router.locale)?.translation;

    if (!translations && (!isServer || process.env.NEXT_PUBLIC_NX_API_ROUTE)) {
      translations = await fetch(`${process.env.NEXT_PUBLIC_NX_API_ROUTE || ''}/api/labels`, {
        headers: {
          'Accept-Language': appContext.router.locale,
        },
      }).then((response) => response.json());
      i18next.addResourceBundle?.(appContext.router.locale, 'translation', translations);
    }

    return {
      ...appProps,
      deviceType,
      translations: translations || {},
      locale: appContext.router.locale,
    };
  };

  return hoistNonReactStatics(AppWithTranslation, WrappedComponent);
};

export default appWithTranslation;
