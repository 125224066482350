import React from 'react';
import { Language } from '../types';

export const SlugContext = React.createContext<{
  slugs: Record<string, Record<Language, string>>;
  setSlugs: (value: Record<string, Record<Language, string>>) => void;
}>({
  slugs: {},
  setSlugs: () => null,
});
